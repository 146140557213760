import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CapodannoFull from "../components/CapodannoFull"
import Form from "../components/form"

const SanSilvestro = () => (
  <Layout>
    <SEO title="Cenone di San Silvestro - 31 Dicembre 2019" />
    <div className="custom-container evento">
      <div className="row">
        <div className="col-lg-5">
          <CapodannoFull />
        </div>
        <div className="col-lg-7">
          <h1>Cenone di San Silvestro</h1>
          <h4>31 Dicembre 2019</h4>
          <section className="contatti">
            <Form formTitle="Prenora Ora" />
          </section>
          
        </div>
      </div>
    </div>
  </Layout>
)

export default SanSilvestro
